<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Chat Notification Details</h3>
            </div>
          </div>

          <div class="card-body">
            <!--begin::Form-->
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                label="Email"
                :rules="emailRules"
                required
              ></v-text-field>
              <v-text-field
                v-model="hours"
                label="Hours"
                :rules="[(v) => !!v || 'This field is required']"
                required
              ></v-text-field>

              <div class="fv-plugins-message-container">
                <div class="fv-help-block errorMsg">
                  {{ error }}
                </div>
                <div
                  v-show="elementVisible"
                  class="fv-help-block successMsg"
                  v-if="successMsg != ''"
                >
                  {{ successMsg }}
                </div>
              </div>
              <v-btn
                :disabled="!valid || loading || isDisable"
                color="success"
                class="mr-4"
                @click="validate"
                :loading="loading"
              >
                {{ "Save" }}
              </v-btn>
            </v-form>
            <!--end::Form-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.successMsg {
  color: #28a745;
}
.errorMsg {
  color: #dc3545;
}
.fv-help-block {
  padding-bottom: 10px;
  font-size: 15px;
}
.search-bar {
  justify-content: flex-end;
}
.loading-div {
  height: 65%;
  width: 100%;
  z-index: 1;
  // background: red;
  position: absolute;
  bottom: 60px;
  // opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script>
import { getDatabase, ref, child, get, set, update } from "firebase/database";

const dbRef = ref(getDatabase());

export default {
  data: () => ({
    valid: true,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    email: "",
    hours: "",
    error: "",
    successMsg: "",
    loading: false,
    elementVisible: true,
    search: "",
    isDisable: false,
  }),
  components: {},
  computed: {},
  mounted() {
    this.getDetails();
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.addUser();
      }
    },

    async addUser() {
      this.loading = true;

      try {
        if (this.email !== "" && this.hours !== "") {
          const db = getDatabase();
          set(ref(db, "notify"), {
            notifyemail: this.email,
            hours: this.hours,
          }).then(() => {
            this.loading = false;
            this.successMsg = "Data Saved Successfully!";
            setTimeout(() => (this.elementVisible = false), 2000);
            this.reset();
            this.getDetails();
          });
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.error = "";
      }
    },
    async getDetails() {
      get(child(dbRef, `notify/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            this.email = snapshot.val().notifyemail;
            this.hours = snapshot.val().hours;
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  beforeMount() {
    this.getDetails();
  },
  created() {
    this.getDetails();
  },
};
</script>
